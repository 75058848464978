<template>
  <section class="mb-8">
    <v-row class="mt-n2" align="center" dense>
      <v-col>
        <div class="heading">Корзина</div>
      </v-col>
      <v-col cols="auto">
        <v-btn
          text
          color="error"
          class="clear-button px-0"
          @click="clearCart"
          :loading="clearButtonLoading"
          :disabled="cartIsEmpty"
          :style="{ opacity: cartIsEmpty ? 0 : 1 }"
        >
          <v-icon class="mr-1">{{ icons.mdiDelete }}</v-icon
          >Очистить корзину
        </v-btn>
      </v-col>
    </v-row>

    <!-- <v-row class="heading secondary--text" align="center">
      <v-col>Сумма товаров:</v-col>
      <v-col cols="auto">{{itemsPrice}} {{ $store.getters["app/currency"] }}</v-col>
    </v-row> -->

    <!-- <template v-if="cart.pts_label_earn && cart.points_enabled">
      <p class="pts-label primary--text">{{cart.pts_label_earn}}</p>
    </template> -->
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { mdiDelete } from "@mdi/js";

export default {
  data: () => ({
    clearButtonLoading: false,
    icons: {
      mdiDelete,
    },
  }),

  computed: {
    ...mapGetters("checkout", ["cartIsEmpty", "cartTotal", "itemsPrice"]),
    ...mapState("checkout", ["cart"]),
  },

  methods: {
    async clearCart() {
      this.clearButtonLoading = true;
      await this.$store.dispatch("checkout/clearCart");
      this.clearButtonLoading = false;
    },
  },
};
</script>
