<template>
  <div class="mb-12">
    <template v-for="(group, i) in recommendations">
      <div v-if="group.items && Object.keys(group.items).length" :key="i">
        <div class="font-weight-black text-center mb-2">
          {{ group.name }}
        </div>
        <RecommendB
          v-if="group.type === 1"
          :items="group.items"
          :from="group.name"
        />
        <RecommendA
          v-if="group.type === 2"
          :items="group.items"
          :from="group.name"
        />
      </div>
    </template>
  </div>
</template>

<script>
import RecommendA from './RecommendA.vue'
import RecommendB from './RecommendB.vue'

export default {
  components: {
    RecommendA,
    RecommendB,
  },

  computed: {
    recommendations() {
      return this.$store.getters['checkout/recommendations']
    },
  },
}
</script>
